<template>
  <div class="container">
    <div class="section-half-rounded pt-1 pb-1">
      <div class="jumbotron rounded-0 section-inner left-0 bg-primary overflow-hidden col-lg-12"
        style="background-color: #2e4058 !important;">
        <img src="@/assets/img/generic/img-3.jpg" alt="Image" class="img-as-bg blend-mode--multiply">
      </div>
      <div class="px-3 pt-2 pt-md-4 pb-2" 
        style="min-height: 200px;">
        <div v-if="pitch" 
          class="row mt-3">
          <div class="col-12 col-md-8">
            <div class="col-12 col-md-6 px-0">
              <div class="bg-holder position-relative rounded-soft overlay overlay-100">
                <div v-if="pitch" class="align-self-center">
                  <img :src="pitch.company.logo" alt="Image" class="align-self-center p-1" style="width: 100%">
                </div> <!--/.bg-holder-->
              </div>
            </div>
            <h1 v-if="!pitch.company.logo"
              class="fs-3 fs-md-7 font-weight-bold text-white lh-110">
              {{ pitch ? pitch.company.title : '' }}
            </h1>
            <p class="lead mb-2 text-white mt-2 fs-0 fs-md-1 font-weight-light">
              {{ pitch ? pitch.company.desc : '' }}
            </p>
            
          </div>
          <div v-if="false" 
            class="col-5">
            <div class="d-flex  bg-holder position-relative rounded-soft overlay overlay-100">
              <div v-if="pitch" class="align-self-center">
                <img :src="pitch.company.logo" alt="Image" class="align-self-center" style="width: 100%">
              </div> <!--/.bg-holder-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="media mb-2 mt-4">
      <span class="icon fa-stack mr-2 ml-n1">
        <i class="d-none d-md-flex fas fa-circle fa-stack-2x text-300"></i>
        <!-- <i class="fa-inverse fa-stack-1x text-primary fas fa-percentage"></i> -->
        <i class="fa-inverse fa-stack-1x text-primary fas fa-file-alt pr-md-2" data-fa-transform="shrink-2"></i>
      </span>

      <div class="media-body">
        <h5 class="mb-0 text-primary position-relative">
          <span class="title bg-200 pr-3">
            Потенциальная возможность
          </span>
          <span class="divider border position-absolute absolute-vertical-center w-100 z-index--1 l-0"></span>
        </h5>
        <p class="sub-title mb-0">
          Описание идеи и потенциала рынка
        </p>
      </div>
    </div>

    <section class="slice pt-2 pb-3">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 pr-md-2 mb-3">
          <div class="card h-100">
            <div class="card-header bg-light d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Решаемая проблема</h5>
            </div>
            <div class="card-body px-2 px-md-3">
              <div v-if="pitch ? pitch.problem.selectedType == 'desc' : false">
                <p><strong class="text-800">Описание:</strong></p>
                <p>{{pitch ? pitch.problem.desc : ''}}</p>
              </div>
              <div v-if="pitch ? pitch.problem.selectedType == 'list' : false">
                <p><strong class="text-800">Основные характеристики:</strong></p>
                <ul class="list-group list-group-flush">
                  <li v-for="(item, index) in (pitch.problem.list)" :key="item.index"
                    class="list-group-item pb-0 pt-2 border-0"
                    :class="{'d-none': item.text == ''}">
                    <div>
                      <small>{{index + 1}}.</small> {{item.text}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <div class="card h-100">
            <div class="card-header bg-light d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Предлагаемое решение</h5>
            </div>
            <div class="card-body px-2 px-md-3">
              <div v-if="pitch ? pitch.solution.selectedType == 'desc' : false">
                <p><strong class="text-800">Описание:</strong></p>
                <p>{{pitch ? pitch.solution.desc : ''}}</p>
              </div>
              <div v-if="pitch ? pitch.solution.selectedType == 'list' : false">
                <p><strong class="text-800">Основные характеристики:</strong></p>
                <ul class="list-group list-group-flush">
                  <li v-for="(item, index) in pitch.solution.list" :key="item.index"
                    class="list-group-item pb-0 pt-2 border-0"
                    :class="{'d-none': item.text == ''}">
                    <div v-if="item.text">
                      <small>{{index + 1}}.</small> {{item.text}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-12 col-md-6 pr-md-2 mb-3">
          <div class="card h-100">
            <div class="card-header bg-transparent d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">
                Целевой рынок
              </h5>
            </div>
            <div class="card-body px-2 px-md-3">
              <div v-if="pitch ? pitch.market.selectedType == 'chart' : false">          
                <div class="row">
                   <div class="col-12 col-md-6 px-0">
                   <div style="width: 100%;">
                      <market-chart 
                        :chartData="marketChartData"
                        :prospectus="totalMarket.prospectus" 
                        :marketSize="totalMarket.marketSize" 
                      />
                   </div>
                  </div>
                  <div class="col-12 col-md-6 pt-3 pt-md-0 pr-2 d-flex align-items-center">
                    <div class="fs--0 fs-md--2">
                      <div v-for="(item, index) in chartSections" :key="item.index"
                        class="d-flex align-items-center">
                        <div class="chart-legend_point mr-3"
                          :class="`point${index+1}`">
                        </div>
                        <div class="chart-legend_title">
                          <strong class="mr-2">
                            {{item.title}}
                          </strong>
                          <small> 
                            {{marketShare(item.totalSpendings) | numeralFormat('0.0%')}} 
                            (₽ {{ item.totalSpendings | numeralFormat('(0a)') }})
                          </small>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
              <div v-if="pitch ? pitch.market.selectedType == 'list' : false">
                <p><strong class="text-800">Основные характеристики:</strong></p>
                <ul class="list-group list-group-flush">
                  <li v-for="(item, index) in pitch.market.list" :key="item.index"
                    class="list-group-item pb-0 pt-2 border-0"
                    :class="{'d-none': item.text == ''}">
                    <div v-if="item.text">
                      <small>{{index + 1}}.</small> {{item.text}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <div class="card h-100">
            <div class="card-header bg-light d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">
                Конкурентное окружение
              </h5>
            </div>
            <div class="card-body p-0">
              <div class="falcon-data-table mt-3">
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row mx-1">
                    <div class="col-sm-12 col-md-6"></div>
                    <div class="col-sm-12 col-md-6"></div>
                  </div>
                  <div class="table-responsive">
                    <table 
                      class="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                      <thead class="bg-200 text-900">
                        <tr role="row">
                          <th class="align-middle text-600 font-weight-light" 
                            style="min-width: 150px">
                            Конкурент
                          </th>
                          <th class="align-middle text-600 font-weight-light">
                            Наше преимещество
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="pitch">
                        <tr v-for="(item, index) in pitch.competitors.list" :key="item.index"
                          class="btn-reveal-trigger" role="row">
                          <td class="p-2 align-middle white-space-nowrap customer-name-column">
                            <a href="#">
                              <div class="media d-flex align-items-center">
                                <div class="media-body">
                                  <h5 class="mb-0 fs--1">
                                    <small class="text-600">{{index + 1}}. </small>
                                    {{item.title}}
                                  </h5>
                                </div>
                              </div>
                            </a>
                          </td>
                          <td class="py-2 align-middle">
                            {{item.desc}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="pitch ? pitch.investments.amount > 0 : false"
          class="col-12">
          <div class="card">
            <div class="bg-holder bg-card" 
              style="background-image:url(/img/illustrations/corner-1.png);">
            </div>
            <!--/.bg-holder-->
            <div class="card-body position-relative">
              <div class="row">
                <div class="col-7 border-right">
                  <h5 class="text-warning fs-0">
                    Потребность в финансировании
                  </h5>
                  <p class="fs--1 mb-0">
                    {{pitch ? pitch.investments.desc : ''}}
                  </p>
                </div>
                <div class="col-5 d-flex justify-content-center align-items-center">
                  <span class="mr-3 align-self-start">₽</span> 
                  <h3 class="font-weight-bold">
                    {{pitch.investments.amount | numeralFormat('0a')}}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>

    <div class="media mb-2 mt-4">
      <span class="icon fa-stack mr-2 ml-n1">
        <i class="fas fa-circle fa-stack-2x text-300"></i>
        <i class="fa-inverse fa-stack-1x text-primary fas fa-cart-plus" data-fa-transform="shrink-2"></i>
      </span>
      <div class="media-body">
        <h5 class="mb-0 text-primary position-relative">
          <span class="title bg-200 pr-3">
            Продажи и Маркетинг
          </span>
          <span class="divider border position-absolute absolute-vertical-center w-100 z-index--1 l-0"></span>
        </h5>
        <p class="sub-title mb-0">
          Комплекс мероприятий позволяющий дотянуться до потенциальных потребителей 
        </p>
      </div>
    </div>

    <section class="slice pt-2 pb-3">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 pr-lg-2 mb-3">
          <pitch-section :pitchSectionData="pitch ? pitch.sales: {}" title="Каналы сбыта" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <pitch-section :pitchSectionData="pitch? pitch.marketing: {}" title="Маркетинг" />
        </div>
        <div class="col-12 mb-3">
          <div class="card h-100">
            <div class="card-header bg-light d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Прогноз выручки</h5>
            </div>
            <div class="card-body px-0">
              <pitch-chart-revenue 
                chartId="publicPitch" 
                :startYear="pitch ? pitch.company.startYear : 0"
                :revenueData="pitch ? pitch.charts.revenue : []" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="media mb-2 mt-3">
      <span class="icon fa-stack mr-2 ml-n1">
        <i class="fas fa-circle fa-stack-2x text-300"></i>
        <i class="fa-inverse fa-stack-1x text-primary fas fa-chart-bar" data-fa-transform="shrink-2"></i>
      </span>
      <div class="media-body">
        <h5 class="mb-0 text-primary position-relative">
          <span class="title bg-200 pr-3">
            Прогноз развития
          </span>
          <span class="divider border position-absolute absolute-vertical-center w-100 z-index--1 l-0"></span>
        </h5>
        <p class="sub-title mb-0">
          Основные прогнозные показатели и этапы проекта
        </p>
      </div>
    </div>

     <section class="slice pt-2 pb-3">
      <div class="row no-gutters">
        <div
          class="col-12 mb-3">
          <div class="card h-100">
            <div class="card-header bg-light d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">Финансовый прогноз</h5>
            </div>
            <!-- <div class="card-body px-0 d-none d-md-block">
              <pitch-chart-forecast />
            </div> -->
            <div class="card-body px-0">
              <pitch-chart-forecast 
                :chartData="pitch ? pitch.charts.financialHighlights : []" 
                :startYear="pitch ? pitch.company.startYear : 0" />
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="card h-100">
            <div class="card-header bg-light d-flex flex-between-center py-2"
              @click="printInfo()">
              <h5 class="mb-0 text-500 font-weight-light">Ключевые этапы</h5>
            </div>
              <div class="card-body px-0">  
                <swiper ref="swiper"
                  class="pb-3"
                  :options="swiperOptions">          
                  <swiper-slide v-for="milestone in milestones" :key="milestone.index"
                  class="px-5">
                    <div class="media btn-reveal-trigger">
                      <div class="calendar">
                        <span class="calendar-month">{{ milestone.deadline | moment("MMM") }}</span>
                        <span class="calendar-day">{{ milestone.deadline | moment("D") }}</span>
                      </div>
                      <div class="media-body position-relative pl-3">
                        <h6 class="fs-0 mb-0">
                          {{ milestone.title }} 
                        </h6>
                        <p class="mt-2">
                          <span  
                            class="badge badge badge-soft-success">
                            Завершен
                            <span class="ml-1 fas fa-check" data-fa-transform="shrink-2"></span>
                          </span> 
                        </p>
                        
                      </div>
                    </div>
                    <p><small>{{ milestone.desc }} </small></p>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                  <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                  <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                </swiper>
              </div>
          </div>
        </div>
      </div>
    </section>  

    <div class="media mb-2 mt-3">
      <span class="icon fa-stack mr-2 ml-n1">
        <i class="fas fa-circle fa-stack-2x text-300"></i>
        <i class="fa-inverse fa-stack-1x text-primary fas fa-user-friends" data-fa-transform="shrink-4"></i>      
      </span>
      <div class="media-body">
        <h5 class="mb-0 text-primary position-relative">
          <span class="title bg-200 pr-3">
            Комманда и Ресурсы
          </span>
          <span class="divider border position-absolute absolute-vertical-center w-100 z-index--1 l-0"></span>
        </h5>
        <p class="sub-title mb-0">
          Наша комманда и ресурсы, которые позволят достичь запланированные показатели
        </p>
      </div>
    </div>

    <section class="slice pt-2 pb-3">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 pr-lg-2 mb-3">
          <div class="card h-100">
            <div class="card-header bg-light d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">
                Ключевые участники
              </h5>
            </div>
            <div class="card-body p-0">
              <div class="falcon-data-table mt-3">
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="table-responsive">
                    <table class="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                      <tbody v-if="pitch">
                        <tr v-for="(item) in pitch.team.list" :key="item.index"
                          class="btn-reveal-trigger" role="row">
                          <td class="py-1 px-1 align-middle white-space-nowrap customer-name-column">
                            <a href="#">
                              <div class="media d-flex align-items-center">
                                <div class="media-body">
                                  <h5 class="mb-0 fs--2">
                                    <div class="avatar avatar-4xl">
                                      <img :src="item.avatar != null ? item.avatar : `@/assets/img/team/2.jpg`" alt="">
                                    </div>
                                  </h5>
                                </div>
                              </div>
                            </a>
                          </td>
                          <td class="py-1 align-middle customer-name-column pl-3">
                              <div class="media d-flex align-items-center">
                                <div class="media-body">
                                  <span class="fs-0 text-1000">
                                    {{item.fio}}
                                  </span>
                                  <h6 class="mt-1 text-400 font-weight-light">
                                    {{item.position}}
                                  </h6>
                                </div>
                              </div>
                              <div class="text-600">
                                 {{item.desc}}
                              </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <div class="card h-100">
            <div class="card-header bg-light d-flex flex-between-center py-2">
              <h5 class="mb-0 text-500 font-weight-light">
                Ключевые ресурсы
              </h5>
            </div>
            <div class="card-body p-0">
              <div class="falcon-data-table mt-3">
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row mx-1">
                    <div class="col-sm-12 col-md-6"></div>
                    <div class="col-sm-12 col-md-6"></div>
                  </div>
                  <div class="table-responsive">
                    <table 
                      class="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                      <tbody v-if="pitch">
                        <tr v-for="(item) in pitch.resources.list" :key="item.index"
                          class="btn-reveal-trigger" role="row">
                          <td class="p-1 align-middle white-space-nowrap customer-name-column">
                            <a href="#">
                              <div class="media d-flex align-items-center">
                                <div class="media-body">
                                  <h5 class="mb-0 fs--1">
                                    <div class="avatar resource_image">
                                      <img :src="item.image != null ? item.image : `@/assets/img/team/2.jpg`" alt="">
                                    </div>
                                  </h5>
                                </div>
                              </div>
                            </a>
                          </td>
                          <td class="py-1 align-middle customer-name-column pl-2">
                            <div class="media d-flex align-items-center">
                              <div class="media-body">
                                <h6 class="fs-0">
                                  {{item.title}}
                                </h6>
                              </div>
                            </div>
                            <div class="text-600">
                              {{item.desc}}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="bg-dark py-2 border-top border-600">
      <div class="row no-gutters justify-content-between fs--1 mt-0 pt-0 mb-1">
        <div class="col-12 col-sm-auto text-center align-content-center">
          <div class="d-flex align-content-center mb-0 text-600">
            <div class="align-self-center">
              Создано при помощи онлайн-платформы
            </div>
            <br class="d-sm-none" /> 
            <small>
              <router-link class="navbar-brand fs-1 mx-2" to="/">
                <div class="d-flex align-items-center text-primary">
                  <img 
                  class="mr-1" 
                  src="@/assets/img/illustrations/bplan-logo.png" 
                  style="filter: contrast(150%);" alt="" width="35" />
                  <span class="text-white font-proxima">
                    ТВОЙ
                    <span class="text-800 fs--2">
                      бизнес-план
                    </span> 
                  </span>
                </div>
              </router-link>
            </small>
            
          </div>

        </div>
    </div>
    
  </footer>
  
  </div>
</template>

<script>
import moment from 'moment';

import PitchSection from '@/components/app/widgets/Pitch/PitchPreviewSegment'
import MarketChart from '@/components/app/charts/MarketDonut';
import PitchChartRevenue from '@/components/app/charts/PublicPitchChartRevenueRenderer';
// import PitchChartForecast from '@/components/app/charts/PitchChartRenderer';
import PitchChartForecast from '../../components/app/charts/PublicPitchChartFinancialHighlights';

export default {
  metaInfo() {
    return {
      title: this.pitch ? this.pitch.company.title : '',

      meta: [
        {property: 'og:title', content: this.pitch ? this.pitch.company.title : '' },
        {property: 'og:site_name', content: 'ТВОЙ бизнес-план'},
        {property: 'og:type', content: 'website'},
        {property: 'og:url', content: 'your-plan.ru'},
        {property: 'og:image', content: 'https://your-plan.ru/img/generic/bg-7.jpg'},
        {property: 'og:image:width', content: '2709' },
        {property: 'og:image:height', content: '938' },
        {property: 'og:description', content: this.pitch ? this.pitch.company.desc : '' },
        
        {name: "twitter:card", content: "/img/generic/bg-7.jpg" },
        {name: "twitter:title", content: "ТВОЙ бизнес-план" },
        {name: "twitter:description", content: this.pitch ? this.pitch.company.desc : '' },
        {name: "twitter:url", content: "your-plan.ru" },
        {name: "twitter:image", content: "https://your-plan.ru/img/generic/bg-7.jpg" },
        {name: "twitter:site", content: "@your_plan" },

      ]
    }
  },
  data: () => ({
    slides: 3,
  }),
  
  components: {
    MarketChart,
    PitchSection,
    PitchChartRevenue,
    // PitchChartForecast,
    PitchChartForecast
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  mounted() {
    this.$store.dispatch('fetchPublicPitch', this.$route.query.id);
    // this.$store.dispatch('fetchMilestonesList');
    // CKQOfSySaVbb1zhju4fPZntYAYC2
    
  },

  computed: {
    bplan() {
      //  return this.$store.getters.getActiveBPlan;
      return {}
    },
    
    pitch() {
      const pitch = this.$store.getters.getPublicPitch;
      return pitch;
    },

    milestones() {
      var list = this.pitch.milestones;
      var sortList = list.sort(function(a, b) {
        return moment(a.deadline) - moment(b.deadline); 
      });
      return sortList ? sortList : []
    },

    chartSections: {
      get: function() {
        const pitch = this.pitch;
        if (pitch) {
          return pitch.market.chart.sort(function(a, b) {
            return b.spendings - a.spendings; // сортируем по убыванию
          });
        } else {
          return [];
        }
      },
    },

    swiperOptions: {
      get: function() {
        return {
          slidesPerView: this.slides,
          spaceBetween: 10,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        }
      }, 
      set: function(newValue) {
        this.slides = newValue;
      }
      
    },

    totalMarket() {
      var totalMarket = {prospectus: 0, marketSize: 0}
      this.chartSections.map(item => {
        totalMarket.prospectus += item.prospectus;
        totalMarket.marketSize += item.totalSpendings;
      });

      return totalMarket
    },

    marketChartData() {
      var chartData = []
      chartData = this.chartSections.map(item => {
        return {
          name: item.title, 
          y: item.totalSpendings
        }
      });

      return chartData
    }
  },

  methods: {
    marketShare(amount) {
      console.log(amount, this.totalMarket.marketSize)
      return (amount / this.totalMarket.marketSize)
    },

    printInfo() {
      this.swiperOptions.slidesPerView = 1;
      this.$refs.swiper.updateSwiper()
    },

    handleResize() {   
      if (window.innerWidth < 500) {
        this.$set(this.swiperOptions, 'slidesPerView', 1);
      } else if (window.innerWidth < 750) {
         this.$set(this.swiperOptions, 'slidesPerView', 2);
      } else {
         this.$set(this.swiperOptions, 'slidesPerView', 3);
      }

    }
  }

}
</script>

<style lang="scss" scoped>

.container {
  &.pitch {
    max-width: 950px;
  }
}

.swiper-pagination {
  bottom: 0px;
}

.swiper-button-disabled {
  display: none;
}

.swiper-button-white {
  color: #5e6e82;
}

.resource_image {
  height: 6.125rem;
  width: 10rem;
}


.section-half-rounded {
  padding-top: 7rem;
  padding-bottom: 7rem;
  background: transparent;
  position: relative; 
}

.section-half-rounded .section-inner {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -2; 
}

@media (max-width: 991.98px) {
  .section-half-rounded .section-inner {
    width: 100%; 
  } 
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #FFF;
  border-radius: 0.5rem; 
  min-height: 200px;
}


@media (max-width: 576px) {
  .container {
    padding: 0 5px;
  }

  .section-half-rounded {
    margin-left: 0; 
    margin-right: 0;
  }
  
  .card-body {
    padding: 15px 10px;
  }

  .media {
    .icon {
      position: relative;
      border-radius: 0 1rem 1rem 0 !important;
      left: -10px;
      background: #344050;
      i {
        left: 2px;
        padding-right: 0;
      }
    }
    .media-body {
      .title {
        position: relative;
        top: -12px;
        font-size: 1.1rem;
      }
      .sub-title {
        font-size: 0.9rem;
        font-weight: 100;
        color: #5e6e82;
      }
      .divider {
        width: 110% !important;
        top: 16px;
        left: -35px;
      }
    }
  }

}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  } 
  
}

.jumbotron {
  position: relative; 
}
  
.jumbotron:not([class*="rounded-"]) {
  border-radius: 2rem; 
}

.jumbotron:not([class*="rounded-"]) > img {
  border-radius: 2rem; 
}

.jumbotron.rounded-0 {
  border-radius: 0 !important; 
}
  
.jumbotron.rounded {
  border-radius: 2rem !important; 
}

.jumbotron.rounded-right {
  border-radius: 0 2rem 2rem 0 !important; 
}

.jumbotron.rounded-left {
  border-radius: 5rem 0 0 5rem !important; 
}
  
.jumbotron.rounded-top-right {
  border-radius: 0 5rem 0 0 !important; 
}

.jumbotron.rounded-top-left {
  border-radius: 5rem 0 0 0 !important; 
}

.jumbotron.rounded-bottom-right {
  border-radius: 0 0 5rem 0 !important; 
}

.jumbotron.rounded-bottom-left {
  border-radius: 0 0 0 5rem !important; 
}

.jumbotron.rounded-diagonal-left {
  border-radius: 5rem 0 5rem 0 !important; 
}
  
.jumbotron.rounded-diagonal-right {
  border-radius: 0 5rem 0 5rem !important; 
}

// .rounded-right {
//   border-top-right-radius: 0.25rem !important;
//   border-bottom-right-radius: 0.25rem !important; }

.img-as-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover; 
  -o-object-fit: cover;
}

.blend-mode--multiply {
  mix-blend-mode: multiply; 
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .blend-mode--multiply:after {
    content: '';
    background: rgba(0, 0, 214, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%; 
  } 
}

.zindex-100 {
  z-index: 100 !important; 
}

.translate-y-n10, .hover-translate-y-n10:hover {
  transform: translateY(-10px) !important; 
}

.chart-legend_point {
  display: -moz-inline-box;
  width: 10px;
  height: 10px !important;
  border-radius: 2px;
  &.point1 {
    background: #0a93cc;
  }
  &.point2 {
    background: #f8d054;
  }
  &.point3 {
    background: #11b70b;
  }
  &.point4 {
    background: #eda027;
  }
  &.point5 {
    background: #8378c7;
  }
}

.chart-legend_point {
  display: -moz-inline-box;
  height: 30px;
}

</style>>

