<template>
  <div style="position: relative; height: 300px; overflow: hidden;">
    <b-overlay 
      :show="loading"
      spinner-variant="primary"
      spinner-small 
      rounded="sm">
      <div class="d-flex align-content-between">
        <div style="width: 100%">
          <highcharts :options="updatedChartOptions"></highcharts>
        </div>
      </div>
    </b-overlay>
    
  </div>
</template>

<script>

export default {
  props: {
    chartData: Array, 
    startYear: {
      type: Number,
      default: 0  
    },
  },
  data: () => ({
    chartOptions: { 
      chart: {
        type: 'column',
        marginLeft: 0,
        marginRight: 0,
        width: null,
        height: 300,
        backgroundColor: null
      },
      credits: false,
      title: {
        text: null
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        x: 0,
        y: 10,
        itemStyle: {
          'color': '#5e6e82',
          'font-family': 'ProximaNova',
          'fontWeight': 'light'
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          colors: ['#9da9bb','#9da9bb','#9da9bb'],
          borderColor: false,
          borderWidth: 0
        },
      },
      yAxis: {
        softMin: 0,
        showFirstLabel: false,
        border: 1,
        minorTickInterval: 'auto',
        minorGridLineWidth: 0.2,
        gridLineWidth: 1,
        gridLineColor: '#344050',
        minorGridLineColor: '#344050',
        plotLines: [{
          value: 0,
          width: 2,
          color: '#808080'
        }],
        labels: {
          align: 'left',
          enabled: true,
          x: 2,
          y: 11,
          style: {
            color: '#919FB2',
            fontSize: 10
          },
        },
        title: {
          text: null,
        },
      },
      xAxis: {
        categories: [
          `0 год`,
          `1 год`,
          `2 год`,
        ],
        labels: {
          style: {
            color: 'lightgray',
            fontSize: 11
          },
        }
      },
      series: [{
        data: [0, 0, 0],
        name: ['Выручка'],
        color: '#4C9BEE'
      }, {
        data: [0, 0, 0],
        name: 'Расходы',
        color: '#f6a09a'
      }, {
        data: [0, 0, 0],
        name: 'Прибыль',
        color: '#2ecc71',
        negativeColor: '#FF0000'
      }]
    },
    revenueChartOptions: null,
    expnsChartOptions: null,
    netProfitChartOptions: null,
    loading: false,
  }),
  async beforeMount() {
    // this.loading = true;

    // await this.chartData;
    
    // this.loading = false;
  },

  computed: {
     updatedChartOptions() {
      var chartOptions = this.chartOptions;
      chartOptions.series = this.chartData;
      chartOptions.xAxis.categories = [
        `${this.startYear} год`,
        `${this.startYear + 1} год`,
        `${this.startYear + 2} год`,
      ];
      
      return chartOptions;
    }
    
  },
}
</script>

<style lang="scss" scoped>

</style>

