<template>
  <div class="chart" 
    style="position: relative; max-height: 300px; overflow: hidden;"
    ref="revenueChart">
    <b-overlay 
      :show="loading"
      spinner-variant="primary"
      spinner-small 
      rounded="sm">
      <highcharts :options="updatedOptions"></highcharts>
      <div
        class="row text-center h5 p-0 text-500 font-weight-thin"
        style="position: relative; top: -4rem;">
        <div class="col-4 px-0">
          {{ this.startYear }}
        </div>
        <div class="col-4 px-0">
          {{ this.startYear + 1 }}
        </div>
        <div class="col-4 px-0">
          {{ this.startYear + 2 }}
        </div>
      </div>
    </b-overlay>
    
  </div>
</template>

<script>
import numeral from 'numeral';

export default {
  props: {
    chartId: String, 
    revenueData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    startYear: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    chartOptions: { 
      chart: {
        type: 'spline',
        marginLeft: 0,
        marginRight: 0,
        width: null,
        height: 300,
        backgroundColor: null,
      },
      credits: false,
      title: {
        text: null
      },
      legend: false, 
       tooltip: {
        crosshairs: true,
        shared: true,
        formatter: function () {
          const index = this.points[0].point.x
          const prevPointData = this.points[0].series.yData[index - 1]
          const pointData = this.points[0].series.yData[index]
          var change = prevPointData ? (pointData / prevPointData - 1) : 0

          return '<b>' + numeral(this.y).format('(0.0a)') + '</b><br>' + 'изм.: <b>' + numeral(change).format('0%') + '</b>'
        }
      },
      yAxis: {
        min: 0,
        showEmpty: true,
        tickPixelInterval: 50,
        showFirstLabel: false,
        border: 1,
        minorTickInterval: 'auto',
        minorGridLineWidth: 1,
        gridLineWidth: 1,
        gridLineColor: '#344050',
        minorGridLineColor: '#3440502a',
        labels: {
          align: 'left',
          enabled: true,
          x: 2,
          y: 15,
          style: {
            color: '#919FB2',
          },
        },
        title: {
          text: null,
        },
        plotLines: [{
          color: '#3a3939e4',
          width: 1,
          value: 0,
          zIndex: 1
        }]
      },
      xAxis: {
        categories: [
          'Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нояб','Дек',
          'Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нояб','Дек',
          'Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нояб','Дек',
        ],
        labels: {
          align: 'left',
          step: 2,
          style: {
              color: '#9da9bb',
            }
        },
        plotLines: [{
          color: '#3440502a', // Color value
          value: 12, // Value of where the line will appear
          width: 1 // Width of the line    
        }, {
          color: '#3440502a', // Color value
          value: 24, // Value of where the line will appear
          width: 1 // Width of the line    
        }]
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: false
          },
          enableMouseTracking: true
        },
        series: {
          stickyTracking: false,
          dataLabels: {
            enabled: false,
          },
          marker: {
            fillColor: '#0e1c2f',
            lineWidth: 5,
            radius: 0,
            lineColor: null,
            symbol: 'circle', // inherit from series
          }
        }
      },
      series: [{
        data: [],
        lineWidth: 4,
        color: '#4C9BEE',
        fillColor: '#adcdf7b3',
      }]
    },
    loading: false,
  }),

  created() {
    this.chartOptions.series[0].data = this.revenueData;
  },

  async mounted() {
    this.loading = true;
    
    if (this.chartId != 'publicPitch') {
      this.chartOptions = await this.$store.dispatch(`revenueChartAllTimeData`, {width: null, height: 300});
    } 
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.loading = false;
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  computed: {
    activeBPlan() {
      return this.$store.getters.getActiveBPlan;
    },

    updatedOptions() {
      var chartOptions = this.chartOptions;
      chartOptions.series[0].data = this.revenueData;
      return chartOptions;
    }
  },
   methods: {

    handleResize() {   
      if (window.innerWidth < 500) {
        this.chartOptions.xAxis.labels.step = 4;
      } else if (window.innerWidth < 750) {
        this.chartOptions.xAxis.labels.step = 3;
      } else {
        this.chartOptions.xAxis.labels.step = 2;
      }

    }
  }
}
</script>

<style lang="scss" scoped>
highcharts-root {
    height: 100% !important;
    max-height: 300px !important;
    width: 100% !important;
    max-width: 920px !important;

}
</style>

